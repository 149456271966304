var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card" }, [
    _vm.$slots.header
      ? _c("div", { staticClass: "card-header" }, [_vm._t("header")], 2)
      : _vm._e(),
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _vm._t("title-content", function () {
          return [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-7" }, [
                _c(
                  "div",
                  { staticClass: "numbers pull-left" },
                  [_vm._t("title")],
                  2
                ),
              ]),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "pull-right" },
                  [_vm._t("title-label")],
                  2
                ),
              ]),
            ]),
          ]
        }),
        _vm._t("subtitle"),
        _c(_vm.chartComponent, {
          tag: "component",
          attrs: {
            labels: _vm.chartData.labels,
            color: _vm.color,
            title: _vm.chartTitle,
            "extra-options": _vm.chartOptions,
            height: _vm.chartHeight,
            datasets: _vm.chartData.datasets,
            data: _vm.chartData.series,
          },
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "card-footer" },
      [
        _vm._t("footer", function () {
          return [
            _c("hr"),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-7" }, [
                _c(
                  "div",
                  { staticClass: "footer-title" },
                  [_vm._t("footer-title")],
                  2
                ),
              ]),
              _c("div", { staticClass: "col-sm-5" }, [
                _c(
                  "div",
                  { staticClass: "pull-right" },
                  [_vm._t("footer-right")],
                  2
                ),
              ]),
            ]),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }