var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.statsCards, function (stats) {
        return _c(
          "div",
          { staticClass: "col-lg-4 col-md-4 col-sm-12" },
          [
            _c(
              "stats-card",
              {
                class:
                  !stats.footerText && !stats.footerValue
                    ? "card-hide-footer"
                    : "",
                attrs: {
                  type: stats.type,
                  icon: stats.icon,
                  "small-title": stats.title,
                  title: stats.value,
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "stats",
                    attrs: { slot: "footer" },
                    slot: "footer",
                  },
                  [
                    _c("i", { class: stats.footerIcon }),
                    _vm._v(
                      "\n          " + _vm._s(stats.footerText) + "\n          "
                    ),
                    _c("span", { staticClass: "pull-right" }, [
                      _vm._v(_vm._s(stats.footerValue)),
                    ]),
                  ]
                ),
              ]
            ),
          ],
          1
        )
      }),
      0
    ),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "line-chart col-sm-6" }, [
        _c(
          "div",
          { staticClass: "card" },
          [
            _c("line-chart", {
              attrs: { "chart-data": _vm.statisticUserCheckout },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "line-chart col-sm-6" }, [
        _c(
          "div",
          { staticClass: "card" },
          [_c("line-chart", { attrs: { "chart-data": _vm.statisticRevenue } })],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "card-body" },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.races } },
                [
                  _c("el-table-column", {
                    attrs: { property: "title", label: "Cuộc đua" },
                  }),
                  _c("el-table-column", {
                    attrs: { property: "joined", label: "Số người đăng ký" },
                  }),
                  _c("el-table-column", {
                    attrs: { property: "price", label: "Giá" },
                  }),
                  _c("el-table-column", {
                    attrs: { property: "total", label: "Tổng cộng" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h5", { staticClass: "pull-left" }, [_vm._v("Các cuộc đua gần đây")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }