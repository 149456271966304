<template>
  <div>
    <!--Stats cards-->
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-12" v-for="stats in statsCards">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="stats.value"
                    :class="(!stats.footerText && !stats.footerValue)?'card-hide-footer':''">
          <div class="stats" slot="footer">
            <i :class="stats.footerIcon"></i>
            {{stats.footerText}}
            <span class="pull-right">{{stats.footerValue}}</span>
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="line-chart col-sm-6">
        <div class="card">
          <line-chart :chart-data="statisticUserCheckout"></line-chart>
        </div>
      </div>
      <div class="line-chart col-sm-6">
        <div class="card">
          <line-chart :chart-data="statisticRevenue"></line-chart>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5 class="pull-left">Các cuộc đua gần đây</h5>
          </div>
          <div class="card-body">
            <el-table :data="races">
              <el-table-column property="title" label="Cuộc đua"></el-table-column>
              <el-table-column property="joined" label="Số người đăng ký"></el-table-column>
              <el-table-column property="price" label="Giá"></el-table-column>
              <el-table-column property="total" label="Tổng cộng"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { Table, TableColumn} from 'element-ui';
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard';
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import { mapState } from 'vuex'
  import LineChart from './line-chart.js'


  export default {
    components: {
      StatsCard,
      ChartCard,
      ElTable: Table,
      ElTableColumn: TableColumn,
      LineChart
    },
    data () {
      return {
        listRace: [],
        showStatistic: false,
      }
    },
    methods: {
      fillData () {
      }
    },
    computed: {
      ...mapState(['dashboard', 'statsCards', 'statisticRevenue', 'statisticUserCheckout']),
      races() {
        let data = this.$store.state.dashboard;
        return data.races;
      }
    },
    mounted() {
      // this.$store.dispatch('fetchDashboard');
      this.$store.dispatch('setPageTitle', 'Bảng điều khiển');
    },
  }

</script>
<style>
  .title-item {
    width: 20px;
    height: 20px;
    margin-right: 5px
  }
</style>
